import $ from 'jquery';
import 'what-input';
import 'magnific-popup';

const owlCarousel = require('owl.carousel');
window.fn = owlCarousel;

window.jQuery = $;

import './lib/foundation-explicit-pieces';

$(document).foundation();
$(document).ready(function() {
    $(".event-list").owlCarousel({
        dots: true,
        responsive: {
            0: {
                items: 1
            },
            640: {
                items: 2
            },
            1024: {
                items: 3
            }
        }
    });

    $('.gallery-item').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true,
            tCounter: '%curr% von %total%'
        }
    });
    $('.lightbox').magnificPopup({type: 'image'});
});